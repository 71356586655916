<template>
  <b-card>
    <form-component @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/blog/categories/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/blog/categories';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  methods: {
    onSubmit(form) {
      create(form)
        .then(() => {
          this.showSuccessNotification('Dane zostały zapisane', 'Kategoria została dodana.');
          this.$router.push({ name: 'blog-categories-index' });
        })
        .catch((error) => {
          const message =
            error.response.data.message ?? 'Wystąpił problem z edycją kategorii. Skontaktuj się ze swoim developerem.';

          this.showErrorNotification('Problem z zapisaniem danych', message);
        });
    },
  },
};
</script>
