<template>
  <b-form @submit.prevent="onSubmit" class="form-container">
    <b-col>
      <b-form-group label="Język" label-for="v-language-select">
        <v-select
          id="v-language-select"
          label="value"
          placeholder="Wybierz język"
          v-model="form.lang"
          :clearable="false"
          :deselectFromDropdown="false"
          :options="languages" />
      </b-form-group>

      <b-form-group label="Nazwa" label-for="v-value">
        <validation-provider name="Nazwa" rules="required|min:3|max:100" v-slot="{ errors }">
          <b-form-input id="v-value" placeholder="Nazwa kategorii" v-model="form.name" :disabled="!form.lang" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col class="mt-5">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" :disabled="!form.lang">
        Zapisz
      </b-button>
    </b-col>
  </b-form>
</template>

<script>
import { getLanguages } from '@/constants/languages';

export default {
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        lang: '',
        name: '',
      },
      languages: getLanguages(),
    };
  },

  watch: {
    category: {
      handler(newValue) {
        this.form = {
          lang: this.languages.find((lang) => lang.lang === newValue.lang),
          name: newValue.name,
        };
      },
    },
  },

  methods: {
    onSubmit() {
      const data = {
        ...this.form,
        lang: this.form.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-form-box {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  & > .row {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }
}
</style>
